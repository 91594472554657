import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button, Card, CardBody, CardHeader, Input, FormGroup, Label} from 'reactstrap';
import MaskedInput from 'react-maskedinput'
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AboutMe extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            password: '',
            pls_reset: this.props.match.params.action
        };
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        document.title = "About Me";
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/user')
            .then(function (response) {
                self.setState(response.data);
                self.setState({
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    updateReminder = (value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        }
        this.setState({
            reminder: value
        });
    };

    submitForm = (form_name, event) => {
        event.preventDefault();
        var self = this;
        var data = self.state;
        data.form = form_name;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/user', data)
            .then(function (response) {
                Swal({title: response.data.Message, type: "success"});
                self.setState({
                    pls_reset: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>About Me</div>
                </div>
                <div class={(this.state.pls_reset ? "alert alert-danger text-center" : "d-none")} role="alert">
                    <b>Please set a new password below</b>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardHeader>
                                User Information
                            </CardHeader>
                            <CardBody>
                                <form className="form-horizontal" onSubmit={this.submitForm.bind(this, 'info')}>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">First Name</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="first_name" value={this.state.first_name} required onChange={this.handleChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Last Name</label>
                                        <div className="col-xl-9">
                                            <Input type="text" name="last_name" value={this.state.last_name} required onChange={this.handleChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Email</label>
                                        <div className="col-xl-9">
                                            <Input type="email" name="email" value={this.state.email} required onChange={this.handleChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Cell Phone</label>
                                        <div className="col-xl-9">
                                            <MaskedInput type="tel" name="phone" className="form-control" mask="(111) 111-1111" size="10" onChange={this.handleChange}
                                                         value={this.state.phone}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label">Order Reminder Emails</label>
                                        <div className="col-xl-9">
                                            <Input className="ml-1" type="checkbox" name="reminder" value="1" checked={(this.state.reminder == "1" ? true : false)}
                                                   onChange={(event) => this.updateReminder(!this.state.reminder)}/>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="offset-xl-3 col-xl-9 mt-3">
                                            <button className="btn btn-primary" type="submit">Save Changes</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="card-default">
                            <CardHeader>
                                Change Password
                            </CardHeader>
                            <CardBody>
                                <form className="form-horizontal" onSubmit={this.submitForm.bind(this, 'password')}>
                                    <Row>
                                        <label className="col-xl-3 col-form-label">New Password</label>
                                        <div className="col-xl-9">
                                            <Input type="password" className="form-control" name="password" value={this.state.password} onChange={this.handleChange}/>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="offset-xl-3 col-xl-9 mt-3">
                                            <button className="btn btn-primary" type="submit">Set New Password</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ContentWrapper>
        );
    }
}

export default (AboutMe);
