import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button, Card, CardHeader, CardBody, Alert, FormGroup, Input, Label} from 'reactstrap';

class AuthorizedUsers extends React.Component {

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Authorized Users</div>
                </div>

                <Alert color="info" className="text-center">
                    <em className="fa fa-info"></em> You can add multiple authoized users who can place and review orders
                </Alert>

                <Card>
                    <CardHeader>
                        Current Authorized Users
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="First Name" value="Adam" disabled/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="Last Name" value="Ouimet" disabled/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="Title" value="Big Dog" disabled/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input type="select" name="select" id="exampleSelect">
                                    <option>Owner</option>
                                    <option selected>User</option>
                                </Input>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked/>{' '}
                                        Order Online
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked/>{' '}
                                        Order by SMS
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked/>{' '}
                                        Order by Phone
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                            </Col>
                        </Row>
                        <Row style={{'borderTop': '1px solid #efefef', 'paddingTop': '10px', 'marginTop': '10px'}}>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="First Name" value="Duncan" disabled/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="Last Name" value="Enman" disabled/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="Title" value="Bar Manager" disabled/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input type="select" name="select" id="exampleSelect">
                                    <option>Owner</option>
                                    <option selected>User</option>
                                </Input>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked/>{' '}
                                        Order Online
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked/>{' '}
                                        Order by SMS
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked/>{' '}
                                        Order by Phone
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <Button outline color="danger" title="Remove this user"><em className="fa fa-remove"></em></Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        Invited Users
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={8} md={6} lg={4}>
                                <Input placeholder="Email Address" value="someuser@email.com" disabled/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="Title" value="Accountant"/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input type="select" name="select" id="exampleSelect">
                                    <option>Owner</option>
                                    <option selected>User</option>
                                </Input>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox"/>{' '}
                                        Order Online
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox"/>{' '}
                                        Order by SMS
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox"/>{' '}
                                        Order by Phone
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <Button outline color="danger" title="Remove this user"><em className="fa fa-remove"></em></Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader>
                        Add New User
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="First Name"/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="Last Name"/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input placeholder="Title"/>
                            </Col>
                            <Col sm={4} md={3} lg={2}>
                                <Input type="select" name="select" id="exampleSelect">
                                    <option>Owner</option>
                                    <option>User</option>
                                </Input>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox"/>{' '}
                                        Order Online
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox"/>{' '}
                                        Order by SMS
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox"/>{' '}
                                        Order by Phone
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <Button outline color="success" title="Add this user"><em className="fa fa-check"></em></Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </ContentWrapper>
        );
    }
}

export default (AuthorizedUsers);
