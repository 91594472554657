import React, {Component} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';

import ToggleState from '../Common/ToggleState';
import HeaderRun from './Header.run'

class Header extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        HeaderRun();
    }

    state = {
        dropdownOpen: false
    }

    changeAccount = account_id => {
        this.props.updateActiveAccount(account_id);
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    active_account_name = () => {
        for (var i in this.props.accounts) {
            if (this.props.accounts[i].account_id == this.props.active_account) {
                return this.props.accounts[i].name;
            }
        }
        //below code only runs if "active_account" is not in the list. e.g. account was removed
        console.log('reset account')
        localStorage.removeItem('active_account');
    }
    active_account_address = () => {
        for (var i in this.props.accounts) {
            if (this.props.accounts[i].account_id == this.props.active_account) {
                return this.props.accounts[i].address1;
            }
        }
    }

    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/dashboard">
                            <div className="brand-logo">
                                <img className="img-fluid" src="https://order.symbevbrands.com/img/logo.png" alt="Symbev"/>
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="https://order.symbevbrands.com/img/logo.png" alt="Symbev"/>
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <ToggleState state="aside-toggled" nopersist={true}>
                                <a href="" className="nav-link sidebar-toggle d-md-none">
                                    <em className="fas fa-bars"></em>
                                </a>
                            </ToggleState>
                        </li>
                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        <div className="ml-auto navbar-account-name">
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} size="lg">
                                <DropdownToggle caret className="text-left">
                                    {this.active_account_name()} <small className="d-none d-sm-inline text-muted">{this.active_account_address()}</small>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem header>Select Account:</DropdownItem>
                                    {[...this.props.accounts].map((x, i) =>
                                        <DropdownItem style={{whiteSpace: 'normal'}} key={i}
                                                      onClick={() => this.changeAccount(this.props.accounts[i].account_id)}>{this.props.accounts[i].name} <small
                                            className="text-muted">{this.props.accounts[i].address1}</small></DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </ul>
                    { /* END Right Navbar */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }

}

export default Header;
