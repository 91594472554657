const Menu = [
    {
        name: 'Dashboard',
        path: 'dashboard',
        icon: 'icon-home',
        translate: 'sidebar.nav.DASHBOARD'
    },
    {
        name: 'Place an Order',
        path: 'place-order',
        icon: 'icon-basket',
        translate: 'sidebar.nav.PLACE_AN_ORDER'
    },
    {
        name: 'Order History',
        path: 'orders',
        icon: 'icon-calendar',
        translate: 'sidebar.nav.ORDER_HISTORY'
    },
    {
        name: 'Payment Method',
        path: 'payment-method',
        icon: 'icon-credit-card',
        translate: 'sidebar.nav.PAYMENT_METHOD'
    },
    {
        name: 'About Me',
        path: 'aboutme',
        icon: 'icon-user',
        translate: 'sidebar.nav.ABOUTME'
    },

    {
        name: 'Support',
        path: 'support',
        icon: 'icon-support',
        translate: 'sidebar.nav.SUPPORT'
    }
];

export default Menu;
